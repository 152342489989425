import { render, staticRenderFns } from "./ContainerGroup.vue?vue&type=template&id=3a065d40&scoped=true&"
import script from "./ContainerGroup.vue?vue&type=script&lang=ts&"
export * from "./ContainerGroup.vue?vue&type=script&lang=ts&"
import style0 from "./ContainerGroup.vue?vue&type=style&index=0&id=3a065d40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a065d40",
  null
  
)

export default component.exports