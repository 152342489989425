

















import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { BlokContainerGroup } from '~/types';

@Component
export default class ContainerGroup extends Vue {
  @Prop() blok!: BlokContainerGroup;

  get columns (): number {
    return parseInt(this.blok.columns.slice(0, 1));
  }

  get columnRatio (): string | false {
    return this.columns === 2 ? this.blok.columns.split('_')[1] : false;
  }
}
